import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Option from './Options';

const Language = ({
  options,
  initial,
}) => {
  const [selected, setSelected] = useState(null);
  const [opened, setOpened] = useState(false);

  function openSelect () {
    setOpened(! opened);
  }

  function onSelect (option) {
    setSelected(option);
  }

  const classes = classNames(
    'menu__language-options',
    opened && 'menu__language-options--show',
  );

  const select = selected
    ? selected.value : initial;

  return (
    <div
      className="menu__box"
      onClick={openSelect}
    >
      <span className="menu__language-select">{select}</span>
      <ul className={classes}>
        {options && options.map((o) => (
          <Option
            key={o.key}
            option={o}
            onSelect={onSelect}
          />
        ))}
      </ul>
    </div>
  );
};

Language.propTypes = {
  options: PropTypes.array.isRequired,
  initial: PropTypes.string,
};

Language.defaultProps = {
  initial: '',
};

export default Language;
