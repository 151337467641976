import './Pagination.scss'

import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import Button from 'icts-button'
import PropTypes from 'prop-types'

const factory = ({ pages, limit, margin, current_page }) => {
  const elements = {}
  const half_range = Math.floor(limit / 2)

  for (let i = 0; i < margin; i++) {
    if (i >= pages) {
      continue
    }
    elements[i] = i + 1
  }

  let low_range =
    current_page - half_range > 0 ? current_page - 1 - half_range : 0

  let high_range = low_range + limit - 1

  if (high_range >= pages) {
    high_range = pages - 1
    low_range = high_range - limit + 1
  }

  for (
    let index = low_range;
    index <= high_range && index <= pages - 1;
    index++
  ) {
    elements[index] = index + 1
  }

  if (low_range > margin) {
    elements[low_range - 1] = 'left'
  }

  if (high_range + 1 < pages - margin) {
    elements[high_range + 1] = 'right'
  }

  for (let index = pages - 1; index >= pages - margin; index--) {
    if (index <= 0) {
      continue
    }
    elements[index] = index + 1
  }

  return elements
}

const Pagination = ({
  total,
  pages,
  limit,
  margin,
  per_page,
  onPaginate,
  current_page,
  url,
}) => {
  const [left, setLeft] = useState(false)
  const [right, setRight] = useState(false)
  const history = useHistory()

  if (pages <= 1) {
    return null
  }

  const pagination = factory({
    pages,
    limit,
    margin,
    current_page,
  })
  const elements = []

  for (const index in pagination) {
    const page = pagination[index]

    if (page === 'left') {
      elements.push(
        <Button
          size="sm"
          key={`${page}`}
          text={left ? '' : '...'}
          type="secondary"
          icon={left ? 'seta-baixo' : ''}
          onMouseEnter={() => setLeft(true)}
          onMouseLeave={() => setLeft(false)}
          className="pagination__button pagination__button--left"
          onClick={() => go(current_page - limit)}
        />,
      )

      continue
    }

    if (page === 'right') {
      elements.push(
        <Button
          size="sm"
          key={`${page}`}
          text={right ? '' : '...'}
          type="secondary"
          icon={right ? 'seta-baixo' : ''}
          className="pagination__button pagination__button--right"
          onMouseEnter={() => setRight(true)}
          onMouseLeave={() => setRight(false)}
          onClick={() => go(parseInt(current_page, 10) + parseInt(limit, 10))}
        />,
      )

      continue
    }

    if (page > 0) {
      elements.push(
        <Button
          size="sm"
          text={`${page}`}
          type={page === current_page ? 'primary' : 'secondary'}
          className="pagination__button"
          key={`${index}${page}`}
          onClick={() => go(page)}
          active={page === current_page}
        />,
      )
    }
  }

  const go = (page) => {
    if (page === current_page || page === '...') {
      return
    }
    onPaginate(page)
    history.push(url + '?page=' + page)
  }

  const previousPage = () => {
    if (current_page === 1) {
      return
    }
    onPaginate(current_page - 1)
    history.push(url + '?page=' + (current_page - 1))
  }

  const nextPage = () => {
    if (current_page === pages) {
      return
    }
    onPaginate(current_page + 1)
    history.push(url + '?page=' + (current_page + 1))
  }

  return (
    <nav className="pagination">
      <span className="pagination__info">
        {per_page * current_page - per_page + 1} -{per_page * current_page} de{' '}
        {total}
      </span>

      <Button
        size="sm"
        type="secondary"
        className="pagination__button pagination__button--left"
        icon="seta-baixo"
        onClick={previousPage}
        disabled={current_page === 1}
      />

      {elements}

      <Button
        size="sm"
        type="secondary"
        icon="seta-baixo"
        className="pagination__button pagination__button--right"
        onClick={nextPage}
        disabled={current_page === pages}
      />
    </nav>
  )
}

Pagination.propTypes = {
  limit: PropTypes.number,
  margin: PropTypes.number,
  onPaginate: PropTypes.func,
  total: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  current_page: PropTypes.number.isRequired,
  url: PropTypes.string,
}

Pagination.defaultProps = {
  limit: 3,
  margin: 1,
  onPaginate: () => {},
  url: '/',
}

export default withRouter(Pagination)
