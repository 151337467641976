export const dashboardCopyRightText = {
  description: `
    * Este relatório foi obtido a partir das respostas fornecidas
    espontaneamente pelo profissional durante o preenchimento do
    formulário, sem qualquer interferência da Aliant. A Aliant não
    se responsabiliza pelas respostas fornecidas pelo respondente
    e pela utilização deste relatório pelo Cliente. De acordo com
    o compromisso de Confidencialidade da Aliant firmado
    contratualmente com seus clientes, após impressão ou cópia
    deste documento, o Cliente torna-se responsável pelas
    informações aqui contidas, sendo importante tratar este
    documento com cuidado e sigilo, sob pena de reparação por
    conta de sua divulgação. Recomendamos que após a leitura,
    realize o descarte ou arquivamento deste documento em local
    seguro. Se você não for o destinatário ou pessoa autorizada a
    visualizá-lo, não utilize, copie, divulgue ou tome qualquer
    ação baseada nestas informações. Se você o recebeu
    indevidamente, avise imediatamente o remetente e apague este
    documento. Grato por sua cooperação.
    `,
  copyrightLicense: `© 2020 Aliant. Todos os direitos reservados. – CONFIDENCIAL – Proibida a reprodução e divulgação`,
  safeCompanyDescription: `*Este relatório foi obtido a partir das respostas fornecidas espontaneamente pelo profissional durante o preenchimento do formulário, sem qualquer interferência da SafeCompany. A SafeCompany não se responsabiliza pelas respostas fornecidas pelo respondente e pela utilização deste relatório pelo Cliente. De acordo com o compromisso de Confidencialidade da SafeCompany firmado contratualmente com seus clientes, após download, impressão ou cópia deste documento, o Cliente torna-se responsável pelas informações aqui contidas, sendo importante tratar este documento com cuidado e sigilo, sob pena de reparação por conta de sua divulgação. Recomendamos que após a leitura, realize o descarte ou arquivamento deste documento em local seguro. Se você não for o destinatário ou pessoa autorizada a visualizá-lo, não utilize, copie, divulgue ou tome qualquer ação baseada nestas informações. Se você o recebeu indevidamente, avise imediatamente o remetente e apague este documento. Grato por sua cooperação.`,
  safeCompanyCopyrightLicense: `© 2023 SafeCompany. Todos os direitos reservados. – CONFIDENCIAL – Proibida a reprodução e divulgação.`,
}
