import './Checkbox.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({
  text, checked, onCheck, disabled, id, name,
}) => {
  const classes = classNames(
    'form-checkbox',
    disabled && 'is-disabled',
    checked && 'is-checked',
  );

  function handleChange () {
    onCheck(! checked);
  }

  return (
    <div className={classes} onClick={() => document.getElementById(id).click()}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        className="form-checkbox__input"
        id={id}
        name={name}
      />
      { text && (
        <label
          htmlFor={text}
          className="form-checkbox__label"
        >
          {text}
        </label>
      ) }
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func,
  text: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  onCheck: () => {},
  text: '',
  id: '',
  name: ''
};

export default Checkbox;
