import './Table.scss'

import Icon from 'icts-icon'
import Pagination from 'icts-pagination'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { prefixFactory } from 'icts-helpers'

const aligns = prefixFactory('table__item--')(['left', 'right', 'center'])

const sizes = prefixFactory('table__col--')([
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
])

const TableHeader = ({
  sort: { order, field } = {},
  header,
  onSort,
  sortable,
  size = 1,
  align = 'left',
}) => {
  const classes = classNames(
    sizes[size],
    aligns[align],
    'table__header-item',
    sortable && 'table__header-item--sortable',
  )
  const icon = classNames(
    'table__header-icon',
    order === 'asc' && 'table__header-icon--up',
  )

  const handleClick = () => {
    if (!sortable) {
      return
    }

    onSort({
      field: sortable,
      order: order === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <div className={classes} onClick={handleClick}>
      {header}

      {sortable && field === sortable && (
        <Icon name="seta-baixo" className={icon} />
      )}
    </div>
  )
}

const TableRow = ({ row, columns, selected, selectable }) => {
  const resolved = columns.map(({ resolver, size, align = 'left' }) => {
    const label = typeof resolver === 'string' ? row[resolver] : resolver(row)

    const classes = classNames(
      sizes[size],
      aligns[align],
      'table__body-item body__default',
    )

    return {
      label,
      classes,
    }
  })

  const valueToBeSelected =
    selectable &&
    (typeof selectable === 'string' ? row[selectable] : selectable(row))

  const isSelected = selected.includes(valueToBeSelected)

  const classes = classNames(
    'table__body-row',
    isSelected && 'table__body-row--selected',
  )

  return (
    <div className={classes}>
      {resolved.map(({ label, classes }, index) => (
        <div key={index} className={classes}>
          {label}
        </div>
      ))}
    </div>
  )
}

const Table = ({
  data,
  sort,
  onSort,
  columns,
  selected,
  onSelect,
  selectable,
  pagination,
}) => (
  <div className="table">
    <div className="table__header">
      <div className="table__header-row">
        {columns.map((column) => (
          <TableHeader
            onSort={onSort}
            sort={sort}
            key={column.header}
            {...column}
          />
        ))}
      </div>
    </div>

    <div className="table__body">
      {data.map((row, index) => (
        <TableRow
          selectable={selectable}
          selected={selected}
          onSelect={onSelect}
          key={index}
          row={row}
          columns={columns}
        />
      ))}
    </div>

    {pagination && <Pagination {...pagination} />}
  </div>
)

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selected: PropTypes.array,
  selectable: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  sort: PropTypes.object,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
  pagination: PropTypes.object,
}

Table.defaultProps = {
  selected: [],
  onSelect: () => {},
  onSort: () => {},
}

export default Table
