import './RangeInput.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'icts-icon';

const RangeInput = ({
  valuePrimary,
  valueSecondary,
  iconPrimary,
  iconSecondary,
}) => {
  const [value, setValue] = useState(0);

  const width = {
    width: `${value * 50}%`,
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="range-input">
      <div className="range-input__target">
        <input
          type="range"
          min="0"
          max="2"
          value={value}
          onChange={handleChange}
          step="0.001"
          className="range-input__inner"
        />
        <div
          className="range-input__bar"
          style={width}
        >
          <span className="range-input__progress" />
          <div className="range-input__tooltip">
            <h3 className="range-input__tooltip-value">{parseInt(value * 50, 10)}</h3>
          </div>
        </div>
      </div>
      <div className="range-input__values">
        { valuePrimary && (
          <>
            <p className="body__small">{valuePrimary}</p>
            <p className="body__small">{valueSecondary}</p>
          </>
        ) }
        { iconPrimary && (
          <>
            <Icon name={iconPrimary} />
            <Icon name={iconSecondary} />
          </>
        )}
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  valuePrimary: PropTypes.string,
  valueSecondary: PropTypes.string,
  iconPrimary: PropTypes.string,
  iconSecondary: PropTypes.string,
};

RangeInput.defaultProps = {
  valuePrimary: '',
  valueSecondary: '',
  iconPrimary: '',
  iconSecondary: '',
};

export default RangeInput;
