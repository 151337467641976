import './Button.scss';
import React from 'react';
import Icon from 'icts-icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prefixFactory } from 'icts-helpers';

const sizesButtons = prefixFactory('button--');
const typesButtons = prefixFactory('button__');

const sizes = sizesButtons([
  'sm',
  'md',
  'lg',
]);

const types = typesButtons([
  'primary',
  'secondary',
]);

const Button = ({
  type,
  size,
  text,
  active,
  icon,
  disabled,
  rotate,
  className,
  ...rest
}) => {
  const variant = classNames({
    'is-disabled': disabled,
    'is-active': active,
  });

  return (
    <button
      {...rest}
      type="button"
      disabled={disabled}
      className={classNames(
        'button',
        variant,
        types[type],
        sizes[size],
        className,
      )}
    >
      { icon && (
      <Icon
        className={`${text ? 'button--icon' : ''}`}
        name={icon}
        rotate={rotate}
      />
      )}
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Active state */
  active: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'lg',
  icon: '',
  text: '',
  disabled: false,
  active: false,
};

export default Button;
