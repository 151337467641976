import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'icts-layout'
import Table from 'icts-table'

import styles from './RelatorioPesquisaMotivacionalAnaliseCultura.module.scss'
import { GeralChart } from './GeralChart'

export const RelatorioPesquisaMotivacionalAnaliseCultura = ({ resultado }) => {
  resultado = Object.keys(resultado).map((key) => resultado[key])

  return (
    <>
      <Row>
        <Col className={styles.dashboardviewer__detalhes_title} xl="12">
          Resultado
        </Col>
      </Row>
      {resultado.map((pergunta) => (
        <Row
          key={pergunta.qid}
          className={styles['dashboardviewer__question-table']}
        >
          <Col>
            <Table
              columns={[
                {
                  align: 'center',
                  header: 'Individual',
                  resolver: ({ result }) => result.user_choice,
                  size: 4,
                },
                {
                  align: 'center',
                  header: 'Geral',
                  resolver: Object.assign(
                    ({ result }) => (
                      <GeralChart
                        result={result.general}
                        text={pergunta.text}
                      />
                    ),
                    { propTypes: { result: PropTypes.object } },
                  ),
                  size: 8,
                },
              ]}
              data={[pergunta]}
            />
          </Col>
        </Row>
      ))}
    </>
  )
}

RelatorioPesquisaMotivacionalAnaliseCultura.propTypes = {
  resultado: PropTypes.object,
}
