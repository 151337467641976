import Icon from 'icts-icon'
import PropTypes from 'prop-types'
import React from 'react'

const Item = ({ type, icon, link, value }) => {
  if (type === 'email') {
    return (
      <a className="footer__social" href={`mailto:${link}`}>
        <Icon name="email" size="sm" className="footer__icon" />
        <p className="footer__value">{value}</p>
      </a>
    )
  } else if (type === 'version') {
    return (
      <span className="footer__social" style={{ cursor: 'pointer' }} href="#">
        <p className="footer__value">{value}</p>
      </span>
    )
  }
  return (
    <a className="footer__social" href={link}>
      <Icon name={icon} size="sm" className="footer__icon" />
    </a>
  )
}

Item.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  link: PropTypes.string,
}

Item.defaultProps = {
  type: 'social',
  icon: '',
  value: '',
}

export default Item
