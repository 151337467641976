import axios from 'axios'
import { kcAuthClient } from './keycloak'

const axiosInstance = () => {
  const config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
  }

  if (kcAuthClient.authenticated && kcAuthClient.token) {
    config.headers = config.headers || {}
    config.headers = {
      Authorization: `Bearer ${kcAuthClient.token}`,
      ...config.headers,
    }
  }

  return axios.create(config)
}

export const api = {
  getUserInfo: async () => {
    try {
      const response = await axiosInstance().get('/user/me')
      return response.data.id ? response.data : null
    } catch (ex) {
      return null
    }
  },

  listEmpresas: async (dashboard) => {
    const response = await axiosInstance().get('/lime-survey/survey-groups', {
      params: {
        dashboard,
      },
    })
    return response.data && response.data.length > 0 ? response.data : []
  },

  listFormularios: async (gsid, dashboard) => {
    const response = await axiosInstance().get(
      `/lime-survey/group/${gsid}/surveys`,
      { params: { dashboard } },
    )
    return response.data && response.data.length > 0 ? response.data : []
  },

  listRespondentes: async (sid) => {
    const response = await axiosInstance().get(
      `/lime-survey/survey/${sid}/participants`,
    )
    return response.data && response.data.length > 0 ? response.data : []
  },

  buscarPerfilEtico: async (sid, tid) => {
    const response = await axiosInstance().get('/dashboard/perfil-etico', {
      params: { sid, tid },
    })
    return response.data ? response.data : null
  },

  buscarPesquisaMotivacionalAnaliseCultura: async (sid, tid) => {
    const response = await axiosInstance().get(
      '/dashboard/pesquisa-motivacional-analise-cultura',
      {
        params: { sid, tid },
      },
    )
    return response.data ? response.data : null
  },
}
