import './Footer.scss'

import PropTypes from 'prop-types'
import React from 'react'

const Footer = ({ logo, children }) => (
  <section className="footer">
    <img src={logo} alt="" className="footer__logo" />
    {children}
  </section>
)

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
}

export default Footer
