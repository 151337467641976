import './Breadcrumb.scss'

import { NavLink, Route } from 'react-router-dom'

import Icon from 'icts-icon'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const crumb = (part, partIndex, parts) => {
  const path = ['', ...parts.slice(0, partIndex + 1)].join('/')
  return (
    <React.Fragment key={partIndex}>
      <Icon name="seta-baixo" className="breadcrumb__icon" />
      <NavLink className="breadcrumb__item" key={path} to={path}>
        {part}
      </NavLink>
    </React.Fragment>
  )
}

const Breadcrumb = ({ className, currentName }) => (
  <Route
    path="*"
    render={(props) => {
      let parts = props.location.pathname.split('/')
      const place = parts[parts.length - 1]
      parts = parts.slice(1, parts.length - 1)
      return (
        <div className={classNames('breadcrumb', className)}>
          <NavLink to="/home" className="breadcrumb__item">
            Home
          </NavLink>
          {parts.map(crumb)}
          <Icon name="seta-baixo" className="breadcrumb__icon" />
          <NavLink
            to={{
              pathname: place,
              search: props.location.search,
            }}
            className="breadcrumb__item breadcrumb__item--active"
          >
            {currentName || place}
          </NavLink>
        </div>
      )
    }}
  />
)

Breadcrumb.propTypes = {
  location: PropTypes.string,
  className: PropTypes.string,
  currentName: PropTypes.string,
}

Breadcrumb.defaultProps = {
  location: '',
}

export default Breadcrumb
