import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { rowContext } from './Row'

const Col = (props) => {
  const { gutter } = React.useContext(rowContext)

  const { children, className } = props

  function getStyle() {
    const style = {}

    if (gutter) {
      style.paddingLeft = `${gutter / 2}px`
      style.paddingRight = style.paddingLeft
    }

    return style
  }

  const classList = []

  ;['span'].forEach((prop) => {
    if (props[prop] >= 0) {
      classList.push(
        prop !== 'span' ? `col-${prop}-${props[prop]}` : `col-${props[prop]}`,
      )
    }
  })
  ;['xs', 'sm', 'md', 'lg', 'xl'].forEach((size) => {
    if (typeof props[size] === 'object') {
      const options = props[size]

      Object.keys(options).forEach((prop) => {
        classList.push(
          prop !== 'span'
            ? `col-${size}-${prop}-${options[prop]}`
            : `col-${size}-${options[prop]}`,
        )
      })
    } else if (props[size] >= 0) {
      classList.push(`col-${size}-${Number(props[size])}`)
    }
  })

  return (
    <div className={classNames('col', classList, className)} style={getStyle()}>
      {children}
    </div>
  )
}

Col.propTypes = {
  className: PropTypes.string,
  span: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xs: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  sm: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  md: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  lg: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  xl: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
}

Col.defaultProps = {
  span: 12,
  children: '',
  xs: 'auto',
  sm: 'auto',
  md: 'auto',
  lg: 'auto',
  xl: 'auto',
}

export default Col
