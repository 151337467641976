import './Menu.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

const Menu = ({ linkBrand, brand, company, children }) => {
  const [show, setShow] = useState(false)

  function handleHamburger() {
    setShow(!show)
  }

  const classMenu = classNames('menu', show && 'menu--hamburger')

  const classBackground = classNames('menu__body', show && 'menu__body--show')

  const classHamburger = classNames(
    'menu__hamburger',
    show && 'menu__hamburger--close',
  )

  return (
    <section className={classMenu}>
      <NavLink className="menu__brand" to={linkBrand}>
        <img src={brand} alt="" className="menu__brand-logo" />
      </NavLink>

      {company && (
        <img src={company} alt="" className="menu__company" width="70px" />
      )}

      <div className={classBackground}>{children}</div>

      <div className={classHamburger} onClick={handleHamburger}>
        <span className="line line-1" />
        <span className="line line-2" />
        <span className="line line-3" />
      </div>
    </section>
  )
}

Menu.propTypes = {
  brand: PropTypes.string.isRequired,
  linkBrand: PropTypes.string.isRequired,
  company: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object || PropTypes.array])
    .isRequired,
}

export default Menu
