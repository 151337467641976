import PropTypes from 'prop-types'
import React from 'react'

const Box = ({ children }) => <div className="menu__right">{children}</div>

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default Box
