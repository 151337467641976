import React from 'react';
import PropTypes from 'prop-types';

const Options = ({
  option,
  onSelect,
}) => {
  function showSelect (e) {
    e.preventDefault();
    onSelect(option);
  }

  return (
    <li
      className="menu__language-option"
      onClick={showSelect}
    >
      {option.value}
    </li>
  );
};

Options.propTypes = {
  option: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Options;
