import './Styles/global.scss'

import { Routes } from './Routes'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { AuthProvider } from './Contexts/auth'
import { GlobalProvider } from './Contexts/global'
import React from 'react'
import ReactDOM from 'react-dom'
import { Header, Footer } from './Views/Layout'
import { Col, Row } from 'icts-layout'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { kcAuthClient } from './Services/keycloak'

ReactDOM.render(
  <ReactKeycloakProvider authClient={kcAuthClient}>
    <Router>
      <GlobalProvider>
        <AuthProvider>
          <Header />
          <Row className="main_content">
            <Col>
              <Switch>
                {Routes.map((routeProps, index) => (
                  <Route key={index} {...routeProps} />
                ))}
              </Switch>
            </Col>
          </Row>
          <Footer />
        </AuthProvider>
      </GlobalProvider>
    </Router>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
)
