import React, { useContext, useState, useRef } from 'react'

import Breadcrumb from 'icts-breadcrumb'
import Card from 'icts-card'
import { Filtros } from './Filtros'
import { GlobalContext } from '../../../../Contexts/global'
import styles from './DashboardViewer.module.scss'
import { useLocation, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { RelatorioPerfilEtico } from './Relatorios/RelatorioPerfilEtico'
import { api } from '../../../../Services/api'
import { RelatorioPesquisaMotivacionalAnaliseCultura } from './Relatorios/RelatorioPesquisaMotivacionalAnaliseCultura'
import { Row, Col } from 'icts-layout'
import { AuthContext } from '../../../../Contexts/auth'
import { dashboardCopyRightText } from './consts'
import Icon from 'icts-icon'
import html2pdf from 'html2pdf.js'

const dashboards = {
  'perfil-etico': {
    name: 'Perfil Ético',
    result: RelatorioPerfilEtico,
    fetchApi: api.buscarPerfilEtico,
  },
  'pesquisa-motivacional-analise-cultura': {
    name: 'Pesquisa Motivacional e Análise de Cultura',
    result: RelatorioPesquisaMotivacionalAnaliseCultura,
    fetchApi: api.buscarPesquisaMotivacionalAnaliseCultura,
  },
}

export const DashboardViewer = () => {
  const cardRef = useRef()
  const location = useLocation()
  const { type: dashboardType } = queryString.parse(location.search)

  const { showSpinner, hideSpinner, showModal } = useContext(GlobalContext)
  const [resultado, setResultado] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)
  const { user } = useContext(AuthContext)

  if (!dashboardType || !dashboards[dashboardType]) {
    return <Redirect to="/dashboard" />
  }

  const ResultComponent = dashboards[dashboardType].result

  const limparResultado = () => {
    setResultado(null)
  }

  const buscarDadosDoRelatorio = async ({
    formulario: sid,
    respondente: tid,
  }) => {
    limparResultado()

    showSpinner()
    try {
      const result = await dashboards[dashboardType].fetchApi(sid, tid)
      setResultado(result)
    } catch (ex) {
      showModal({
        type: 'alerta',
        description: ex.response.data.message,
      })
    } finally {
      hideSpinner()
    }
  }

  return (
    <div ref={cardRef}>
      <Card className={styles.dashboardviewer}>
        {(selectedClient?.label === 'Safe Company') && (
          <div className={styles.dashboardviewer__print_header}>
            <img
              alt="Safe Company"
              className="menu__brand-logo"
              src="/assets/imgs/logo-safe-company.png"
            />
          </div>
        )}
        <div className={styles.dashboardviewer__card_top}>
          <Breadcrumb currentName={dashboards[dashboardType].name} />
          <button
            onClick={() => {
              cardRef.current.className = styles.dashboardviewer__print
              // setTimeout for wait all elements resize
              setTimeout(() => {
                html2pdf(cardRef.current, {
                  margin: 0,
                  filename: 'dashboard.pdf',
                  html2canvas: { scale: 0.8 },
                  image: { type: 'jpeg', quality: 1 },
                  pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy'],
                  },
                })
                cardRef.current.className = ''
              }, 1000)
            }}
          >
            <Icon size="sm" name="download" />
          </button>
        </div>
        <Filtros
          dashboardType={dashboardType}
          onResetFilters={limparResultado}
          setSelectedClient={setSelectedClient}
          onSelectionDone={buscarDadosDoRelatorio}
        />
        {resultado && (
          <>
            <ResultComponent resultado={resultado} />
            <Row>
              <Col>
                <p className={styles.dashboardviewer__termo}>
                  {selectedClient.label === 'Safe Company'
                    ? dashboardCopyRightText.safeCompanyDescription
                    : dashboardCopyRightText.description}
                </p>
                <p className={styles.dashboardviewer__termo}>
                  {selectedClient.label === 'Safe Company'
                    ? dashboardCopyRightText.safeCompanyCopyrightLicense
                    : dashboardCopyRightText.copyrightLicense}
                </p>
                <p className={styles.dashboardviewer__termo}>
                  Usuário: {user.name} – IP: {user.ipAddress} – Data e hora:{' '}
                  {new Date().toLocaleString('pt-BR')}
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </div>
  )
}
