import './Input.scss'

import React, { useEffect, useState } from 'react'

import Button from 'icts-button'
import Dropdown from 'icts-dropdown'
import Icon from 'icts-icon'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { prefixFactory } from 'icts-helpers'

const icons = {
  error: 'erro',
  warning: 'alerta',
  success: 'concluido',
}

const stateKeys = ['success', 'warning', 'error', 'note', 'disabled']
const states = prefixFactory('form-input--')(stateKeys)

const Input = ({
  flag,
  icon,
  label,
  Target,
  prepend,
  children,
  flagColor,
  append,
  dropdown,
  className,
  containerClassName,
  ...rest
}) => {
  const state = stateKeys.find((state) => !!rest[state])
  const [classes, setClasses] = useState('')
  const [classesInput, setClassesInput] = useState('')
  const validation = icons[state]
  const message = rest[state]

  useEffect(() => {
    setClassesInput(
      classNames(
        className,
        'form-input__control',
        flag && 'form-input__credit-card',
        prepend && 'form-input__control--has-prepend',
        append && 'form-input__control--has-append',
        append && prepend && 'form-input__control--has-prepend-and-append',
      ),
    )
  }, [className, flag, prepend, append])

  useEffect(() => {
    setClasses(classNames('form-input', containerClassName, states[state]))
  }, [state, containerClassName])

  return (
    <div className={classes}>
      <label className="form-input__label body__default">
        {label}

        {rest.required && (
          <span className="form-input__label-required">Obrigatório</span>
        )}
      </label>

      <div className={classesInput}>
        {prepend && (
          <div className="form-input__prepend-wrapper">
            <Button {...prepend} className="form-input__prepend" />

            {dropdown && (
              <Dropdown {...dropdown} className="form-input__dropdown" />
            )}
          </div>
        )}

        <div className="form-input__wrapper">
          <Target {...rest} className="form-input__target" />

          {/* For all icons */}
          {icon && (
            <Icon className="form-input__icon-default" name={icon} size="xs" />
          )}
        </div>

        {/* For validation icons */}
        {validation && (
          <Icon
            className="form-input__icon-validation"
            name={validation}
            size="xs"
          />
        )}

        {children}

        {/* For credit card */}
        {flag && (
          <div
            className="form-input__card"
            style={{ backgroundColor: flagColor }}
          >
            <img src={flag} alt="flag" className="form-input__card-flag" />
          </div>
        )}

        {append && <Button {...append} className="form-input__append" />}
      </div>

      {message && <p className="form-input__message body__small">{message}</p>}
    </div>
  )
}

Input.propTypes = {
  note: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  sucess: PropTypes.string,
  warning: PropTypes.string,
  label: PropTypes.string.isRequired,
  flag: PropTypes.string,
  flagColor: PropTypes.string,
  required: PropTypes.bool,
  password: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string,
  prepend: PropTypes.object,
  append: PropTypes.object,
  icon: PropTypes.string,
  Target: PropTypes.func,
  onInput: PropTypes.func,
  mask: PropTypes.string,
  dropdown: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

Input.defaultProps = {
  Target: ({ onInput, ...rest }) => (
    <InputMask {...rest} onChange={(event) => onInput(event.target.value)} />
  ),

  onInput: () => {},
}

export default Input
