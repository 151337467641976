import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { useMapPropToBreakpoint } from 'icts-helpers'
export const rowContext = React.createContext(null)

const Row = (props) => {
  const { children, className } = props

  const gutter = useMapPropToBreakpoint(props, 'gutter', {
    xlarge: 'xl',
    large: 'lg',
    medium: 'md',
    small: 'sm',
    xsmall: 'xs',
  })

  /*
  function getStyle () {
    const style = {};

    if (gutter) {
      style.marginLeft = `-${gutter / 2}px`;
      style.marginRight = style.marginLeft;
    }

    return style;
  }
  */

  const divClass = classNames('container', 'row', className)
  return (
    <rowContext.Provider value={{ gutter }}>
      <div className={divClass}>{children}</div>
    </rowContext.Provider>
  )
}

export default Row

Row.propTypes = {
  /** Default row Gutter  */
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  children: PropTypes.node,
  className: PropTypes.string,
}

Row.defaultProps = {
  gutter: 12,
  xs: 8,
  sm: 24,
  md: 16,
  lg: 8,
  xl: 12,
  children: '',
}
