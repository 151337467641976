import './Card.scss'

import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const Card = ({ heading, children, className }) => (
  <div className={classNames('card', className)}>
    {heading && <h1 className="card__heading">{heading}</h1>}
    {children}
  </div>
)

Card.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
}

export default Card
