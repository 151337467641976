import React, { useState } from 'react'

import Icon from 'icts-icon'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const User = ({ avatar, children, icon, className }) => {
  const [show, setShow] = useState(false)

  function handleShow() {
    setShow(!show)
  }

  return (
    <div className={classNames('menu__box', className)}>
      <div className="menu__user">
        {icon && <Icon name={icon} className="menu__link-icon" size="sm" />}
        {avatar && <img src={avatar} alt="" className="menu__user-avatar" />}
        <button
          type="button"
          className="menu__user-action"
          onClick={handleShow}
        >
          <Icon name="seta-baixo" className="menu__user-icon" />
        </button>
        {show && <div className="menu__user-dropdown">{children}</div>}
      </div>
    </div>
  )
}

User.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  icon: PropTypes.string,
}

User.defaultProps = {
  children: [],
}

export default User
