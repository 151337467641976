import './Radio.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Radio = ({
  text, checked, onCheck, disabled,
}) => {
  const classes = classNames(
    'form-radio',
    disabled && 'is-disabled',
    checked && 'is-checked',
  );

  function handleChange () {
    onCheck(! checked);
  }

  return (
    <div className={classes}>
      <input
        type="radio"
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        className="form-radio__input"
      />
      { text && (
        <label
          htmlFor={text}
          className="form-radio__label"
        >
          {text}
        </label>
      ) }
    </div>
  );
};

Radio.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func,
  text: PropTypes.string,
};

Radio.defaultProps = {
  disabled: false,
  onCheck: () => {},
  text: '',
};

export default Radio;
