import { Col, Row } from 'icts-layout'
import React, { useEffect } from 'react'

import Chart from 'chart.js'
import PropTypes from 'prop-types'
import { Radar } from 'react-chartjs-2'
import Table from 'icts-table'
import styles from './RelatorioPerfilEtico.module.scss'

const stylesRiscos = {
  'SEM ANÁLISE': {
    backgroundColor: '#FC8F47',
    color: '#000',
  },
  BAIXO: {
    backgroundColor: '#00FF3B',
    color: '#000',
  },
  'MÉDIO BAIXO': {
    backgroundColor: '#A8FF6D',
    color: '#000',
  },
  MÉDIO: {
    backgroundColor: '#FFFF99',
    color: '#000',
  },
  'MÉDIO ALTO': {
    backgroundColor: '#FC8F47',
    color: '#000',
  },
  ALTO: {
    backgroundColor: '#FF6666',
    color: '#000',
  },
}

export const RelatorioPerfilEtico = ({ resultado }) => {
  const tableColumns = [
    {
      size: 4,
      header: 'Item pontuado',
      align: 'center',
      resolver: 'item',
    },
    {
      size: 2,
      header: 'Score de risco',
      align: 'center',
      // eslint-disable-next-line react/prop-types
      resolver: ({ score }) => (
        <div
          className={styles.dashboardviewer__score_risco}
          style={stylesRiscos[score]}
        >
          {score}
        </div>
      ),
    },
    {
      size: 7,
      align: 'center',
      header: 'Análise',
      // eslint-disable-next-line react/prop-types
      resolver: ({ analise }) => (
        <div className={styles.dashboardviewer__analise}>{analise}</div>
      ),
    },
  ]

  const getResultadoIndividual = (resultado) => {
    if (resultado === 'SEM ANÁLISE') return resultado
    else return `RISCO ${resultado}`
  }

  useEffect(() => {
    Chart.helpers.merge(Chart.defaults.global, {
      aspectRatio: 4 / 3,
      layout: {
        padding: {
          top: 42,
          right: 16,
          bottom: 32,
          left: 8,
        },
      },
      elements: {
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    })
  }, [])

  if (!resultado) return null

  const tableData = Object.keys(resultado.itensAvaliados).map((key) => ({
    item: key === 'Geral total' ? 'Percepção moral' : key,
    score: resultado.itensAvaliados[key].risco,
    analise: resultado.itensAvaliados[key].analise,
  }))

  const percepcaoMoralIndex = tableData.findIndex(
    (data) => data.item === 'Percepção moral',
  )
  const [percepcaoMoral] = tableData.splice(percepcaoMoralIndex, 1)
  tableData.unshift(percepcaoMoral)

  const transparenciaIndex = tableData.findIndex(
    (data) => data.item === 'Falta de transparência',
  )
  const [transparencia] = tableData.splice(transparenciaIndex, 1)
  tableData.unshift(transparencia)

  const data = Object.keys(resultado.itensAvaliados).reduce(
    (acc, current) => {
      if (current === 'Geral total') return acc
      acc.labels.push(current)
      acc.datasets[0].data.push(
        parseFloat(resultado.itensAvaliados[current].pontos) * 100,
      )
      return acc
    },
    {
      labels: [],
      datasets: [
        {
          label: 'Perfil Ético',
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(179,181,198,1)',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: [],
        },
      ],
    },
  )

  return (
    <>
      <Row>
        <Col className={styles.dashboardviewer__resultado_individual}>
          Resultado individual:{' '}
          {getResultadoIndividual(
            resultado.itensAvaliados['Geral total'].risco,
          )}
        </Col>
      </Row>

      <Row>
        <Col className={styles.dashboardviewer__total_respondido} xl="6">
          <span className={styles.dashboardviewer__total_respondido_label}>
            Questões respondidas
          </span>
          <span className={styles.dashboardviewer__total_respondido_value}>
            {resultado.totalRespondido}%
          </span>
        </Col>
        {parseFloat(resultado.totalRespondido) < 40.0 && (
          <Col className={styles.dashboardviewer__resultado_invalido} xl="6">
            Resultado invalidado. Menos de 40% das questões respondidas.
            Recomendados a realização da entrevista de Compliance Individual
            para aprofundamento.
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Radar
            width={400}
            data={data}
            options={{
              legend: {
                display: false,
              },
              scale: {
                angleLines: {
                  display: false,
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 100,
                },
              },
              tooltips: {
                callbacks: {
                  title: ([tooltipItem], obj) => {
                    const idx = tooltipItem.index
                    return obj.labels[idx]
                  },
                  label: function (tooltipItem, d) {
                    return (
                      'Valor : ' +
                      tooltipItem.yLabel.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      }) +
                      '%'
                    )
                  },
                },
              },
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xl="12" className={styles.dashboardviewer__detalhes_title}>
          Detalhes da pontuação
        </Col>
        <Col xl="12">
          <Table columns={tableColumns} data={tableData} />
        </Col>
      </Row>
    </>
  )
}

RelatorioPerfilEtico.propTypes = {
  resultado: PropTypes.object,
}
