import { Footer as ICTSFooter, Item } from 'icts-footer'

import React from 'react'

export const Footer = () => (
  <ICTSFooter logo="/assets/imgs/logo.svg">
    <Item
      type="email"
      link="portalcompliance@aliant.com.br"
      value="portalcompliance@aliant.com.br"
    />
    <Item link="https://www.facebook.com/somosaliant" icon="facebook" />
    <Item link="https://twitter.com/somosaliant" icon="twitter" />
    <Item link="https://www.linkedin.com/company/somosaliant" icon="linkedin" />
    <Item type="version" value={'Versão: 1.0.0'} />
  </ICTSFooter>
)
