import './SelectInput.scss'

import React, { useState } from 'react'

import Dropdown from 'icts-dropdown'
import Icon from 'icts-icon'
import Input from './Input.jsx'
import classNames from 'classnames'

const Option = ({ item, onRemove }) => {
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onRemove(item)
  }

  return (
    <span className="form-select__option">
      {item.label}

      <Icon
        size="xs"
        name="fechar"
        onClick={handleClick}
        className="form-select__icon"
      />
    </span>
  )
}

const SelectInput = ({
  className,
  sm,
  value,
  items,
  direction,
  disabled,
  placeholder,
  searchable,
  scrollable,
  onSelect,
  ...rest
}) => {
  const Target = ({ className }) => {
    const [show, setShow] = useState(false)
    const [search, setSearch] = useState()

    const classes = classNames(
      'form-select',
      sm && 'form-input__target--sm',
      className,
    )
    const isMulti = Array.isArray(value)

    const handleClick = (event) => {
      event.preventDefault()

      if (disabled) {
        setShow(false)

        return
      }

      setShow(!show)
    }

    return (
      <>
        <div className={classes} onClick={handleClick}>
          {!((isMulti && value.length) || value.label) && (
            <p className="form-input__target-placeholder">{placeholder}</p>
          )}

          {isMulti ? (
            <div className="form-select__options">
              {value.map((item) => (
                <Option onRemove={onSelect} item={item} />
              ))}
            </div>
          ) : (
            value.label
          )}

          {show && !disabled && (
            <Dropdown
              items={items}
              scrollable={scrollable}
              selected={value}
              onSearch={setSearch}
              search={search}
              onSelect={onSelect}
              direction={direction}
              searchable={searchable}
            />
          )}
        </div>

        <Icon
          size="xs"
          name="seta-baixo"
          onClick={handleClick}
          rotate={show ? '180deg' : ''}
          className="form-input__icon-default form-select__arrow"
        />
      </>
    )
  }

  return (
    <Input
      containerClassName={className}
      {...rest}
      Target={Target}
      disabled={disabled}
    />
  )
}

export default SelectInput
