import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'icts-icon';

const Link = ({
  icon,
  value,
  link,
  dropdown,
  children,
}) => {
  const [show, setShow] = useState(false);

  function handleShow () {
    setShow(! show);
  }

  const navLink = (! dropdown && link);

  return (
    <div className="menu__nav-item">
      <NavLink
        className="menu__link"
        activeClassName={! dropdown ? 'menu__link--active' : ''}
        to={navLink}
        onClick={dropdown ? handleShow : undefined}
      >
        <Icon
          name={icon}
          className="menu__link-icon"
          size="sm"
        />
        <p className="menu__link-value">{value}</p>
        {dropdown && (
          <Icon
            name="seta-baixo"
            className="menu__link-icon-dropdown"
          />
        )}
      </NavLink>
      {show && (
        <div
          className="menu__link-dropdown"
          onMouseLeave={handleShow}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Link.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  link: PropTypes.string,
  dropdown: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object || PropTypes.array]),
};

Link.defaultProps = {
  link: '',
  dropdown: false,
  children: [],
};

export default Link;
