import React from 'react'
import PropTypes from 'prop-types'
import { Pie as MyChart } from 'react-chartjs-2'
import { Classic20 as ColorScheme } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
// import { SetThree12 as ColorScheme } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'

import styles from './RelatorioPesquisaMotivacionalAnaliseCultura.module.scss'

export const GeralChart = ({ result, text }) => {
  const data = Object.keys(result).reduce(
    (acc, cur) => {
      acc.datasets[0].data.push(result[cur])
      acc.labels.push(cur)
      return acc
    },
    {
      datasets: [
        {
          backgroundColor: ColorScheme,
          borderColor: '#E9EEF3',
          data: [],
        },
      ],
      labels: [],
    },
  )

  return (
    <div className={styles['dashboardviewer__question-graph']}>
      <p>{text}</p>
      <MyChart
        width={400}
        data={data}
        options={{
          tooltips: {
            callbacks: {
              label: (tooltipItem, obj) => {
                const idx = tooltipItem.index
                const total = obj.datasets[0].data.reduce(
                  (acc, curr) => acc + curr,
                  0,
                )
                const self = obj.datasets[0].data[idx]
                const porcentagem = ((self / total) * 100).toFixed(2)
                return `${obj.labels[idx]}: ${self} (${porcentagem}%)`
              },
            },
          },
          legend: false,
        }}
      />
      <div className={styles['dashboardviewer__question-graph-legend']}>
        <ul>
          {data.labels.map((label, index) => (
            <li key={index}>
              <span
                style={{
                  backgroundColor: data.datasets[0].backgroundColor[index],
                }}
              ></span>
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

GeralChart.propTypes = {
  result: PropTypes.object,
  text: PropTypes.string,
}
