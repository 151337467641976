import React from 'react'
import { Link } from 'react-router-dom'

import Card from 'icts-card'

import styles from './Dashboard.module.scss'
import Breadcrumb from 'icts-breadcrumb'

export const DashboardPage = () => {
  return (
    <Card>
      <Breadcrumb className={styles.header} />
      <Link
        to="/dashboard/view?type=perfil-etico"
        className={styles['dashboard-list-item']}
      >
        Perfil Ético
      </Link>

      <Link
        to="/dashboard/view?type=pesquisa-motivacional-analise-cultura"
        className={styles['dashboard-list-item']}
      >
        Pesquisa Motivacional e Análise de Cultura
      </Link>
    </Card>
  )
}
