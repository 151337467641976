import { Box, Item, Menu, User, UserData } from 'icts-menu'
import React, { useContext } from 'react'

import { AuthContext } from '../../Contexts/auth'
import styles from './Header.module.scss'

export const Header = () => {
  const { user, logout } = useContext(AuthContext)
  return (
    <Menu brand="/assets/imgs/logo.svg" linkBrand="/dashboard">
      <Box>
        <UserData
          perfil={`Perfil: ${user ? user.profile : 'Carregando...'}`}
          login={`Login: ${user ? user.email : 'Carregando...'}`}
        />
        <User className={styles['menu__box--on_top']} icon="usuario">
          <Item link="#" onClick={logout} value="Sair" />
        </User>
      </Box>
    </Menu>
  )
}
