import React from "react";
import PropTypes from "prop-types";

const UserData = ({ perfil, login }) => {
  return (
    <div className="menu__item">
      {perfil}
      <br />
      {login}
    </div>
  );
};

UserData.propTypes = {
  perfil: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired
};

export default UserData;
