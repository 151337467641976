import { Col, Row } from 'icts-layout'
import React, { useContext, useEffect, useState, useCallback } from 'react'

import { AuthContext } from '../../../../Contexts/auth'
import Button from 'icts-button'
import { GlobalContext } from '../../../../Contexts/global'
import PropTypes from 'prop-types'
import { SelectInput } from 'icts-form'
import { api } from '../../../../Services/api'
import classNames from 'classnames'
import styles from './DashboardViewer.module.scss'

export const Filtros = ({
  dashboardType,
  onSelectionDone,
  onResetFilters,
  setSelectedClient,
}) => {
  const { showModal, showSpinner, hideSpinner } = useContext(GlobalContext)
  const { user } = useContext(AuthContext)

  const [disabledEmpresa, setDisabledEmpresa] = useState(false)
  const [empresas, setEmpresas] = useState([])
  const [formularios, setFormularios] = useState([])
  const [respondentes, setRespondentes] = useState([])
  const [empresa, setEmpresa] = useState([])
  const [formulario, setFormulario] = useState([])
  const [respondente, setRespondente] = useState([])

  const changeColorBasedOnCompany = useCallback((empresa) => {
    if (empresa.label === 'Safe Company') {
      document.documentElement.style.setProperty('--color-primary', '#189cd8')
      document.documentElement.style.setProperty('--color-purple-6', '#189cd8')
      document.documentElement.style.setProperty('--color-primary-1', '#189cd8')
      document.documentElement.style.setProperty('--color-primary-2', '#189cd8')
      document.documentElement.style.setProperty(
        '--color-secondary',
        ' #137baa',
      )
    } else {
      document.documentElement.style.setProperty('--color-primary', '#420080')
      document.documentElement.style.setProperty('--color-purple-6', '#350066')
      document.documentElement.style.setProperty('--color-primary-1', '#27004d')
      document.documentElement.style.setProperty('--color-primary-2', '#1a0033')
      document.documentElement.style.setProperty('--color-secondary', '#420080')
    }
  }, [])

  // Effect para gerencias as mudanças no campo empresa (por exemplo carregar questionários relativos a empresa selecionada)
  useEffect(() => {
    setFormulario([])
    setFormularios([])
    setSelectedClient(empresa)
    changeColorBasedOnCompany(empresa)
    ;(async () => {
      if (empresa && !Array.isArray(empresa)) {
        onResetFilters && onResetFilters()
        showSpinner()
        try {
          const listaFormularios = await api.listFormularios(
            empresa.value,
            dashboardType,
          )
          setFormularios(listaFormularios)
        } catch (ex) {
          showModal({
            type: 'alerta',
            description: ex.response.data.message,
          })
        } finally {
          hideSpinner()
        }
      }
    })()
    // eslint-disable-next-line
  }, [empresa])

  useEffect(() => {
    setRespondente([])
    setRespondentes([])
    ;(async () => {
      if (formulario && !Array.isArray(formulario)) {
        onResetFilters && onResetFilters()
        showSpinner()
        try {
          const listaRespondentes = await api.listRespondentes(formulario.value)
          setRespondentes(listaRespondentes)
        } catch (ex) {
          showModal({
            type: 'alerta',
            description: ex.response.data.message,
          })
        } finally {
          hideSpinner()
        }
      }
    })()
    // eslint-disable-next-line
  }, [formulario])

  // Effect para carregar os clientes disponíveis
  useEffect(() => {
    ;(async () => {
      showSpinner()
      try {
        const listaEmpresas = await api.listEmpresas(dashboardType)
        setEmpresas(listaEmpresas)
      } catch (ex) {
        showModal({
          type: 'alerta',
          description: ex.response.data.message,
        })
      } finally {
        hideSpinner()
      }
    })()
    // eslint-disable-next-line
  }, [])

  // Effect para verificar se o usuário é ICTS ou não, ajustando o campo do cliente
  useEffect(() => {
    if (user && user.icts === false) {
      if (empresas.length > 0) setEmpresa(empresas[0])
      setDisabledEmpresa(true)
    }
  }, [user, empresas])

  return (
    <>
      <Row className={styles.dashboardviewer__filtros}>
        <Col xl="6">
          <SelectInput
            className={classNames(
              styles.dashboardviewer__select,
              styles['dashboardviewer__select--full_width'],
              styles['dashboardviewer__select--on_top'],
            )}
            label="Cliente"
            searchable
            items={empresas}
            value={empresa}
            onSelect={setEmpresa}
            disabled={disabledEmpresa}
          />
        </Col>
        <Col xl="6">
          <SelectInput
            className={classNames(
              styles.dashboardviewer__select,
              styles['dashboardviewer__select--full_width'],
              styles['dashboardviewer__select--between'],
            )}
            label="Questionário"
            searchable
            items={formularios}
            value={formulario}
            onSelect={setFormulario}
          />
        </Col>
      </Row>
      <Row className={styles.dashboardviewer__filtros}>
        <Col xl="8">
          <SelectInput
            className={classNames(
              styles.dashboardviewer__select,
              styles['dashboardviewer__select--full_width'],
            )}
            label="Respondente"
            searchable
            items={respondentes}
            value={respondente}
            onSelect={setRespondente}
          />
        </Col>
        <Col className={styles.dashboardviewer__btn_container} xl="2">
          <Button
            type="primary"
            text="Visualizar"
            icon="dashboard"
            size="lg"
            onClick={() => {
              formulario &&
                !Array.isArray(formulario) &&
                respondente &&
                !Array.isArray(respondente) &&
                onSelectionDone &&
                onSelectionDone({
                  formulario: formulario.value,
                  respondente: respondente.value,
                })
            }}
          />
        </Col>
        <Col className={styles.dashboardviewer__btn_container} xl="2">
          <Button
            type="secondary"
            text="Limpar"
            icon="limpar"
            size="lg"
            onClick={() => {
              if (user && user.icts === true) setEmpresa([])
              else setFormulario([])
              onResetFilters && onResetFilters()
            }}
          />
        </Col>
      </Row>
    </>
  )
}

Filtros.propTypes = {
  onSelectionDone: PropTypes.func,
  onResetFilters: PropTypes.func,
  dashboardType: PropTypes.string,
  setSelectedClient: PropTypes.func,
}
