import React, { useState } from 'react'

import Icon from 'icts-icon'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const Item = ({ onClick, link, value, dropdown, children }) => {
  const [show, setShow] = useState(false)

  function handleShow() {
    setShow(!show)
  }

  const navLink = !dropdown && link

  return (
    <div className="menu__item">
      <NavLink
        to={navLink}
        className="menu__item-link"
        onClick={dropdown ? handleShow : onClick}
      >
        {value}
        {dropdown && <Icon name="seta-baixo" className="menu__item-icon" />}
      </NavLink>
      {show && (
        <div className="menu__item-dropdown" onMouseLeave={handleShow}>
          {children}
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  link: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dropdown: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
}

Item.defaultProps = {
  dropdown: false,
  children: [],
}

export default Item
