import './Dropdown.scss'

import Icon from 'icts-icon'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const toValues = (array) =>
  array.reduce((values, { value }) => {
    if (Array.isArray(value)) {
      values.push(...toValues(value))
    } else {
      values.push(value)
    }

    return values
  }, [])

const getLabels = (array) =>
  array.map(({ label, value }) =>
    [label].concat(...(Array.isArray(value) ? getLabels(value) : [])),
  )

const Dropdown = ({
  show,
  items,
  action,
  search,
  onSearch,
  selected,
  onAction,
  onSelect,
  direction,
  className,
  searchable,
  actionIcon,
  actionValue,
  scrollable,
}) => {
  const isMulti = Array.isArray(selected)
  const classes = classNames(
    className,
    'dropdown',
    scrollable && 'dropdown--scrollable',
    direction === 'top' ? 'dropdown--top' : 'dropdown--bottom',
  )

  const regex = new RegExp(search, 'gim')

  const filtered = search
    ? items.filter(({ label, value }) =>
        Array.isArray(value)
          ? getLabels(value).filter((l) => regex.test(l)).length > 0
          : regex.test(label),
      )
    : items

  const handleInput = (event) => {
    onSearch(event.target.value)
  }

  const handleActionInput = (event) => {
    onAction(event.target.value)
  }

  if (!show) {
    return null
  }

  return (
    <div tabIndex="-1" className={classes}>
      {searchable && (
        <div
          onClick={(event) => event.stopPropagation()}
          className="dropdown__search"
        >
          <input
            defaultValue={search}
            onInput={handleInput}
            placeholder="Pesquisar"
            type="text"
            className="dropdown__search-input"
          />
        </div>
      )}

      {action && (
        <div className="dropdown__action">
          <input
            className="dropdown__action-input"
            value={actionValue}
            placeholder={action}
            onInput={handleActionInput}
          />
          <Icon
            onClick={() => onAction(actionValue, true)}
            name={actionIcon}
            className="dropdown__action-icon"
          />
        </div>
      )}

      <ul className="dropdown__list">
        {filtered.map(({ label, value }) => {
          if (label === '' && value === '') {
            return <></>
          }
          const hasNested = Array.isArray(value)
          const values = hasNested && toValues(value)

          const isActive = values
            ? isMulti
              ? selected.findIndex((option) =>
                  values.includes(option.value),
                ) !== -1
              : values.includes(selected.value)
            : isMulti
            ? selected.findIndex((option) => option.value === value) !== -1
            : selected.value === value

          const childrenClasses = classNames(
            'dropdown__item',
            !hasNested && 'dropdown__item--end',
            isActive && 'dropdown__item--active',
          )

          const handleClick = () =>
            !hasNested &&
            onSelect({
              label,
              value,
            })

          return (
            <li
              key={label}
              tabIndex="0"
              onClick={handleClick}
              className={childrenClasses}
            >
              <span className="dropdown__item-label" href="#">
                {label}
              </span>

              <Icon
                name="concluido"
                style={{ opacity: !hasNested && isActive ? 'inherit' : 0 }}
                className="dropdown__item-icon"
              />

              {hasNested && (
                <>
                  <Icon
                    rotate="-90deg"
                    name="seta-baixo"
                    className="dropdown__item-icon dropdown__item-icon--chevron"
                  />

                  <Dropdown
                    items={value}
                    search={search}
                    onSelect={onSelect}
                    selected={selected}
                    direction={direction}
                    className="dropdown__item-dropdown"
                  />
                </>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Dropdown.propTypes = {
  direction: PropTypes.oneOf(['top', 'bottom']),
  actionValue: PropTypes.string,
  searchable: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  action: PropTypes.string,
  actionIcon: PropTypes.string,
  onAction: PropTypes.func,
  scrollable: PropTypes.bool,
  show: PropTypes.bool,
}

Dropdown.defaultProps = {
  search: '',
  show: true,
  className: '',
  selected: [],
  action: '',
  actionIcon: '',
  actionValue: '',
  scrollable: false,
  onAction: () => {},
  onSearch: () => {},
  searchable: false,
  direction: 'bottom',
  onSelect: () => {},
}

export default Dropdown
