import { Redirect as RouterRedirect, useLocation } from 'react-router-dom'

import React from 'react'

const Redirect = () => {
  const location = useLocation()
  return (
    <RouterRedirect
      to={{ pathname: '/404', state: { from: location.pathname } }}
    />
  )
}

const NotFoundPage = () => {
  const location = useLocation()
  return (
    <div
      style={{
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        height: '100%',
      }}
    >
      <h1>
        ERRO 404: o recurso
        {location.state && location.state.from
          ? ` (${location.state.from})`
          : ''}{' '}
        não está disponível.
      </h1>
    </div>
  )
}

NotFoundPage.Redirect = Redirect

export { NotFoundPage }
