import { DashboardPage } from '../Views/Pages/Dashboard'
import { HomePage } from '../Views/Pages/Home'
import { NotFoundPage } from '../Views/Pages/NotFound'
import { DashboardViewer } from '../Views/Pages/Dashboard/DashboardViewer'

/**
 * Rotas da aplicação
 * @type import('react-router-dom').RouteProps[]
 */
export const Routes = [
  {
    path: '/dashboard/view',
    component: DashboardViewer,
    exact: true,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    exact: true,
  },
  { path: ['/', '/home'], component: HomePage, exact: true },
  { path: '/404', component: NotFoundPage, exact: true },
  { path: '*', component: NotFoundPage.Redirect },
]
